<template>
    <div class="apply_number">
        <div class="apply_number_content">
            <div class="content_info">
                <p v-for="(item, index) in info" :key="index">{{item}}</p>
            </div>
            <div class="content_operator">
                <p>了解详情请点击下方运营商链接：</p>
                <div class="content_operator_item" v-for="(operator, index) in operators" :key="index">
                    <img :src="operator.icon"/>
                    <text :class="operator.color" >{{operator.label}}: </text>
                    <a :href="operator.trueurl || operator.url">{{operator.url}}</a>
                    <a class="oprator_button" :href="operator.trueurl || operator.url">戳我了解</a>
                </div>
            </div>
            <div class="content_contact">
                如有疑问请联系客服：<a href="tel:021-64175077">021-64175077</a>/
                <a href="tel:021-64175076">021-64175076</a>
            </div>
        </div>
        <p class="apply_number_tip">上海福彩962333电话投注 千万大奖等着您！</p>
    </div>
</template>
<script lang='ts'>
import { reactive, toRefs } from 'vue'
export default {
    name: '',
    setup() {
        const state= reactive({
          operators: [
            //   {label: '移动', url: 'http://juhaodan.cn/app/ydk.html', color: 'green', icon: require('../../assets/imgs/apply-phone/yidong_icon.png')},
              {label: '联通',trueurl: 'https://icard.usuredata.com/v2/channel/yh2x36', url: 'https://icard.usuredata.com', color: 'red', icon: require('../../assets/imgs/apply-phone/liantong_icon.png')},
              {label: '电信', url: 'https://www.dianxinxingka.com/lb.html', color: 'blue', icon: require('../../assets/imgs/apply-phone/dianxin_icon.png')}
          ],
          info: [
              '欢迎您一键办理移动、联通、电信的上海手机电话卡。',
              '移动、联通、电信运营商正规渠道实名认证，方便您随时参与上海福彩短信投注，千万大奖等着您！'
          ],
        })
        return {
            ...toRefs(state),
        }
    },
}
</script>

<style lang='scss' scoped>
$blue: #12366c;
$green: #61a532;
$red: #c01920;
$orange: #c01920;
$yellow: #ffc44a;
.green {
    color: $green;
}
.blue {
    color: $blue;
}
.orange {
    color: $orange;
}
.yellow {
    color: $yellow;
}
.apply_number {
    background: url('../../assets/imgs/apply-phone/apply_phone_bg.jpg') center center no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    &_content {
        background: #fff;
        margin: 130px $margin-big $margin-big $margin-big;
        border-radius: $box-radius-small;
        padding: 25px 10px;
        .content_info {
            font-weight: 700;
            line-height: 1.6em;
            margin-bottom: 20px;
        }
        .content_operator {
            &>p {
                font-weight: 700;
                color: $orange;
                margin-bottom: 15px;
            }
            &_item {
                display: flex;
                align-items: center;
                position: relative;
                margin-bottom: 20px;
                a {
                    font-weight: 700;
                    color: $blue;
                }
                img {
                    width: 16px;
                    height: auto;
                    margin-right: 1px;
                }
                .oprator_button {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    color: $blue;
                    background-color: $yellow;
                    font-size: $font-less;
                    border-radius: 5px;
                    padding: 3px 10px;
                }
            }
        }
        .content_contact {
            color: $blue;
            padding: 5px 0;
            a {
                color:  $blue;
            }
        }
    }
    &_tip {
        color: rgba(#fff, 0.85);
        text-align: center;
        padding-top: 5px;
    }
    color: $blue;
    font-size: $font-small;
}
</style>